/*------------------------------------*\
    .features
\*------------------------------------*/
.features {
	background-color: rgba(#3b3e52,0.99);
	background-image: linear-gradient(to bottom left, rgba(#354b15, 1), rgba(#1b5752, 1) 2100px);
	position: relative;
	overflow: hidden;



	&::before {
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 500px;
		content: " ";
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
	}

	&::after {
		position: absolute;
		z-index: 50;
		top: 0;
		right: 0;
		display: block;
		width: 100%;
		height: 1080px;
		content: " ";
		background-image: url("../img/section_pattern02.png");
		opacity: 1;
		background-position: 80px -75px;
	}
}

	.features__wrapper {
		position: relative;
		z-index: 200;
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		padding-top: 160px;
		padding-bottom: 200px;
	}


		.features__elements-wrapper {
			margin-top: 20px;

			//clearfix
			&::after {
				display: table;
				content: " ";
				clear: both;
			}
		}

			.features__element {
				display: inline-block;
				width: 49.9%;
				float: left;
				height: 100px;
				margin-top: 80px;
			}

				.features__image {
					background-size: 100px 100px;
					background-position: top center;
					width: 25%;
					min-height: 100px;
					height: 100%;
					//filter: drop-shadow(0 0 7px rgba(0,0,0,0.2));
					float: left;

					&--mobile { background-image: url("../img/feature/feature_mobile.svg"); }
					&--newest { background-image: url("../img/feature/feature_newest.svg"); }
					&--intuitive { background-image: url("../img/feature/feature_intuitive.svg"); }
					&--browsers { background-image: url("../img/feature/feature_browsers.svg"); }
					&--clients { background-image: url("../img/feature/feature_clients.svg"); }
					&--fast { background-image: url("../img/feature/feature_fast.svg"); }
					&--seo { background-image: url("../img/feature/feature_seo.svg"); }
					&--secure { background-image: url("../img/feature/feature_secure.svg"); }
					&--semantic { background-image: url("../img/feature/feature_semantic.svg"); }
					&--sourcefiles { background-image: url("../img/feature/feature_sourcefiles.svg"); }

					//alternative
					//&--mobile { background-image: url("../img/feature/alternative/feature_mobile.svg"); }
					//&--newest { background-image: url("../img/feature/alternative/feature_newest.svg"); }
					//&--intuitive { background-image: url("../img/feature/alternative/bulb.png"); }
					//&--browsers { background-image: url("../img/feature/alternative/browser.png"); }
					//&--clients { background-image: url("../img/feature/alternative/feature_clients.svg"); }
					//&--fast { background-image: url("../img/feature/alternative/speed.png"); }
					//&--seo { background-image: url("../img/feature/alternative/search.png"); }
					//&--secure { background-image: url("../img/feature/alternative/feature_secure.svg"); }
					//&--semantic { background-image: url("../img/feature/alternative/feature_semantic.svg"); }
					//&--sourcefiles { background-image: url("../img/feature/alternative/feature_sourcefiles.svg"); }
				}


				.features__text {
					float: left;
					width: 65%;
				}

					.features__title {
						font-size: 25px;
						line-height: 1;
						padding-bottom: 3px;
						font-weight: 600;
						color: rgba(255,255,255,0.9);
						border-bottom: 2px solid rgba(255,255,255,0.1);
					}

					.features__description {
						margin-top: 2px;
						font-size: 15px;
						font-weight: 300;
						letter-spacing: 0.3px;
						//word-spacing: 0.1px;
						line-height: 1.3;
						color: rgba(255,255,255,0.7);

					}

