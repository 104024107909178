/*------------------------------------*\
	Information
\*------------------------------------*/

	/*
	 * Style by Dominik Serafin
	 * * http://serafin.io/
	 * * dominikdsgnr@gmail.com
	 *
	 * Stylesheet generated with SASS
	 * * http://sass-lang.com/
	 *
	 * Vendor prefixes thanks to Autoprefixer
	 * * https://github.com/postcss/autoprefixer
	 *
	 */





/*------------------------------------*\
    Normalize
\*------------------------------------*/

	@import "normalize-3.0.1";





/*------------------------------------*\
    General styles
\*------------------------------------*/

	html, body {
		position: relative;
		display: block;
		width: 100%;
	}

	html {
		font-family: "Raleway", sans-serif;
	}

	body {
		overflow-y: scroll;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	*, ::before, ::after {
		box-sizing: border-box;
		background-repeat: no-repeat;
	}

	::selection {
		background: yellow;
		color: black;
	}

	p, h1, h2, h3, h4, h5, h6, ul, li {
		margin: 0;
		padding: 0;
	}






/*------------------------------------*\
    Variables
\*------------------------------------*/

	//$example: 10px;





/*------------------------------------*\
    Site sections
\*------------------------------------*/

	.content {
		position: absolute;
		top: 970px;
		left: 0;
		width: 100%;
	}

	.start {
		font-size: 0;
		position: absolute;
		top: 0;
		left: 0;
	}

	.maxwidth1050 {
		font-size: 0;
		position: absolute;
		top: -999px;
		left: -999px;
		display: none;
	}

	@import "block_ghost-button";

	@import "part_header"; //contains PACE plugin styles
	@import "part_hero";
	@import "part_section-header";
	@import "part_experience";
	@import "part_features";
	@import "part_realizations"; //contains ResponsiveSlides plugin styles
	@import "part_contact";
	@import "part_footer";

	@import "responsive";





