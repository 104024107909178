/*------------------------------------*\
    .hero
\*------------------------------------*/
.hero {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -500;
	width: 100%;
	height: 1000px;
	overflow: hidden;
}


	.hero__background {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -400;
		width: 100%;
		height: 100%;

		background-image: url("../img/hero_background.jpg");
		background-repeat: repeat;
	}


	@keyframes HeroGradient {
		0% {
			transform: translate(-80%, -80%);
		}
		100% {
			transform: translate(-2%, -2%);
		}
	}

	.hero__gradient {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -300;
		width: 500%;
		height: 500%;
		opacity: 0.9;
		overflow: hidden;

		//background-image: linear-gradient(to bottom right, #001036, #a8786a );

		background-color: rgba(27, 5, 48, 1);
		background-image: url("../img/hero_gradient.png");
		background-size: 100%;
		background-position: 80% 80%;

		animation-name: HeroGradient;
		animation-duration: 55.5s;
		animation-timing-function: ease;
		animation-delay: 0;
		animation-iteration-count: infinite;
		animation-direction: alternate;

	}

	.hero__pattern {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -200;
		width: 100%;
		height: 100%;
		opacity: 0.5;

		background-image: url("../img/hero_pattern.png");
		background-repeat: repeat-x;
		background-position: bottom left;
	}


	.hero__content {
		padding-top: 70px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -100;
		width: 100%;
	}

		.hero__content-wrapper {
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
		}

			.hero__title-wrapper {
				margin-top: 135px;
				float: left;
			}

				.hero__title-large {
					color: rgba(255,255,255,0.9);
					font-weight: 700;
					font-size: 100px;
				}

				.hero__title-small {
					color: rgba(255,255,255,0.9);
					font-weight: 700;
					font-size: 55px;
					margin-left: 20px;
					margin-top: 15px;
					//cursor: default;
				}


			.hero__photo-wrapper {
				margin-top: 90px;
				float: right;
			}

				.hero__photo {
					position: relative;

					width: 280px;
					height: 280px;
					border-radius: 150px;
					background-image: url("../img/hero_photo.jpg");
					background-size: cover;
				}

					.hero__hat {
						position: absolute;
						top: -60px;
						left: 50px;

						width: 140px;
						height: 110px;
						background-image: url("../img/hero_hat.png");
						background-size: contain;
						transition: transform 210ms ease-in-out;
						transform: rotate(0) translate(0, 0);
					}

					.hero__hat--tip {
						transform: rotate(-30deg) translate(-50px, -30px);
					}


			.hero__description {
				clear: both;
				text-align: center;
				color: rgba(255,255,255,0.9);
				font-size: 24px;
				line-height: 1.8;
				font-weight: 300;
				padding-top: 70px;

				strong {
					font-weight: 600;
				}
				a {
					text-decoration: underline;
				}
			}


			.hero__call-to-action {
				clear: both;
				text-align: center;
				padding-top: 70px;
			}

				.hero__button {
					display: inline-block;
					vertical-align: middle;
					color: rgba(255,255,255,0.9);
					font-size: 22px;
					padding: 15px 60px 15px 60px;
					border-radius: 3px;
				}


				.hero__alt-button {
					vertical-align: middle;
					display: inline-block;
					color: rgba(255,255,255,0.7);
					font-size: 22px;
					margin-left: 50px;
					transition: color 100ms ease;
					cursor: pointer;
					overflow: hidden;
					&:hover { color: rgba(255,255,255,0.9); }
				}
